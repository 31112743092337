import React, { useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import { PageLayout } from '../components/PageLayout';
import { SearchQuery, SearchQueryVariables } from '../../graphql-types';
import { useAppDispatch, useAppSelector } from '../hooks';
import {
  selectDefaultLocale,
  setDefaultLocale,
  setLocale,
} from '../store/i18n';
import { Head } from '../components/Head';
import { Header } from '../components/Header';
import { ContentContainer } from '../components/ContentContainer';
import { Box, colors, TextField, Typography } from '@mui/material';
import { createLocalizedPath, mapTranslations } from '../lib/i18n';
import { GlobalStyles } from '../components/GlobalStyles';
import { Link } from 'gatsby-theme-material-ui';
import xss from 'xss';
import Fuse from 'fuse.js';
import {
  colorBlue,
  colorBlueLight,
} from '../gatsby-theme-material-ui-top-layout/theme';

const highlight = (
  fuseSearchResult: any,
  highlightClassName: string = 'highlight'
) => {
  const set = (obj: object, path: string, value: any) => {
    const pathValue = path.split('.');
    let i;

    for (i = 0; i < pathValue.length - 1; i++) {
      obj = obj[pathValue[i]];
    }

    obj[pathValue[i]] = value;
  };

  const generateHighlightedText = (
    inputText: string,
    regions: number[] = []
  ) => {
    let content = '';
    let nextUnhighlightedRegionStartingIndex = 0;

    regions.forEach((region) => {
      const lastRegionNextIndex = region[1] + 1;

      content += [
        inputText.substring(nextUnhighlightedRegionStartingIndex, region[0]),
        `<span class="${highlightClassName}">`,
        inputText.substring(region[0], lastRegionNextIndex),
        '</span>',
      ].join('');

      nextUnhighlightedRegionStartingIndex = lastRegionNextIndex;
    });

    content += inputText.substring(nextUnhighlightedRegionStartingIndex);

    return content;
  };

  return fuseSearchResult
    .filter(({ matches }: any) => matches && matches.length)
    .map(({ item, matches }: any) => {
      const highlightedItem = { ...item };

      matches.forEach((match: any) => {
        set(
          highlightedItem,
          match.key,
          generateHighlightedText(match.value, match.indices)
        );
      });

      return highlightedItem;
    });
};

export const searchQuery = graphql`
  query Search($id: String!, $pageTitle: String!) {
    siteSettings: file(
      sourceInstanceName: { eq: "settings" }
      name: { eq: "site" }
    ) {
      childMarkdownRemark {
        frontmatter {
          defaultLocale
        }
      }
    }
    pageTranslation: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        locale
      }
    }
    pageTranslations: allFile(
      filter: {
        childMarkdownRemark: { frontmatter: { pageTitle: { eq: $pageTitle } } }
        sourceInstanceName: { eq: "pageTranslations" }
        extension: { eq: "md" }
      }
    ) {
      nodes {
        childMarkdownRemark {
          fields {
            slug
          }
          frontmatter {
            locale
          }
        }
      }
    }
    languages: allLanguagesYaml {
      nodes {
        locale
      }
    }
    countryReports: allFile(
      filter: {
        sourceInstanceName: { eq: "countryReports" }
        extension: { eq: "md" }
      }
    ) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            countryCode
          }
        }
      }
    }
    countryReportTranslations: allFile(
      filter: {
        sourceInstanceName: { eq: "countryReportTranslations" }
        extension: { eq: "md" }
      }
    ) {
      nodes {
        childMarkdownRemark {
          html
          fields {
            slug
          }
          frontmatter {
            locale
            countryCode
            title
          }
        }
      }
    }
  }
`;

export const SearchTemplate = ({
  title,
  content,
}: {
  title: string;
  content: JSX.Element;
}) => (
  <>
    <ContentContainer>
      <Box sx={{ marginBottom: '1rem' }}>{content}</Box>
    </ContentContainer>
  </>
);

const Search = ({
  data,
  location,
}: PageProps<SearchQuery, SearchQueryVariables>) => {
  const dispatch = useAppDispatch();

  const {
    html,
    frontmatter: { title, locale },
  } = data.pageTranslation;

  dispatch(setLocale(locale));
  const defaultLocale =
    data.siteSettings.childMarkdownRemark.frontmatter.defaultLocale;
  dispatch(setDefaultLocale(defaultLocale));

  const pathsByLocale = new Map(
    data.pageTranslations.nodes.map(
      ({
        childMarkdownRemark: {
          fields: { slug },
          frontmatter,
        },
      }) => [frontmatter.locale, slug]
    )
  );

  const currentPath = location.pathname;

  const countryReports = mapTranslations({
    defaultLocale,
    locales: data.languages.nodes.map(({ locale }) => locale),
    data: data.countryReports.nodes,
    translations: data.countryReportTranslations.nodes,
    isTranslationPredicate: (value, translation, locale) => {
      const {
        childMarkdownRemark: {
          frontmatter: { countryCode: itemCountryCode },
        },
      } = value;
      const {
        childMarkdownRemark: {
          frontmatter: {
            countryCode: translationCountryCode,
            locale: translationLocale,
          },
        },
      } = translation;
      return (
        translationCountryCode === itemCountryCode &&
        translationLocale === locale
      );
    },
  }).map(({ value, translations }) => {
    const {
      childMarkdownRemark: {
        frontmatter: { countryCode },
      },
    } = value;
    return {
      countryCode,
      translations: translations.map(({ locale, isFallback, translation }) => {
        const {
          childMarkdownRemark: {
            html,
            fields: { slug },
            frontmatter: { title },
          },
        } = translation;
        return {
          html,
          locale,
          title,
          slug,
          isFallback,
        };
      }),
    };
  });

  const index = new Fuse(
    countryReports
      .flatMap((x) => x.translations)
      .filter((x) => !x.isFallback)
      .map((x) => {
        const strippedHtml = xss(x.html, {
          stripIgnoreTag: true,
          whiteList: {},
        });
        return {
          title: x.title,
          slug: x.slug,
          locale: x.locale,
          html: strippedHtml,
        };
      }),
    {
      keys: [
        { name: 'title', weight: 1 },
        { name: 'html', weight: 0.1 },
      ],
      includeMatches: true,
      ignoreLocation: true,
      includeScore: true,
      minMatchCharLength: 2,
      threshold: 0.2,
    }
  );

  return (
    <>
      <GlobalStyles />
      <Head pageTitle={title} currentPath={currentPath} />
      <Header currentPath={currentPath} pathsByLocale={pathsByLocale} />
      <PageLayout>
        <ContentContainer>
          <SearchReports index={index} label={title} locale={locale} />
        </ContentContainer>
      </PageLayout>
    </>
  );
};

export default Search;

const SearchReports = ({
  index,
  label,
  locale,
}: {
  index: Fuse<{ html: string; locale: string; title: string; slug: string }>;
  label: string;
  locale: string;
}) => {
  const [query, setQuery] = useState('');

  const minChars = 3;

  const hasMinChars = query.length >= minChars;

  const filtered = hasMinChars ? index.search(query) : [];

  const defaultLocale = useAppSelector(selectDefaultLocale);

  return (
    <div>
      <TextField
        id="search"
        label={label}
        variant="standard"
        type="text"
        value={query}
        onChange={(e) => setQuery(e.currentTarget.value)}
        sx={{ marginBottom: '2rem', width: '100%' }}
      />
      {hasMinChars ? (
        filtered.length ? (
          filtered.map((x) => (
            <Box
              key={`${x.item.title}${x.item.locale}`}
              sx={{
                marginBottom: '1.5rem',
                position: 'relative',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: colorBlue,
                borderRadius: '5px',
                transition: 'background 0.3s',
                padding: '1rem',
                ':hover': {
                  bgcolor: colorBlueLight,
                },
              }}
            >
              <Link
                to={createLocalizedPath(
                  [x.item.slug],
                  x.item.locale,
                  defaultLocale
                )}
                className="stretched-link"
                sx={{
                  marginBottom: '0.25rem',
                  typography: 'h3',
                  textDecoration: 'none',
                  color: colors.common.black,
                  ':hover': {
                    textDecoration: 'none',
                  },
                }}
              >
                {x.item.title} ({x.item.locale})
              </Link>

              <Box sx={{ fontSize: '0.9rem' }}>
                {x.item.html.slice(0, 300)}
                {x.item.html.length > 300 ? '...' : ''}
              </Box>
            </Box>
          ))
        ) : (
          <Typography sx={{ fontStyle: 'italic' }}>
            {locale === 'fr' ? 'Pas de résultats.' : 'No search results.'}
          </Typography>
        )
      ) : (
        <Typography sx={{ fontStyle: 'italic' }}>
          {locale === 'fr'
            ? 'Tapez au moins 3 lettres pour commencer la recherche.'
            : 'Type minimum 3 letters to start searching.'}
        </Typography>
      )}
    </div>
  );
};
